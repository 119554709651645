<script setup lang="ts">
import { useMouseInElement } from '@vueuse/core';
import { onMounted, ref } from 'vue';

const containerElRef = ref<HTMLDivElement>()
const { elementX: x, elementY: y } = useMouseInElement(containerElRef, { touch: false, scroll: false })

const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})

</script>

<template>

  <div 
    ref="containerElRef"
    class="bg-brand-stroke/10 rounded-3 relative border-effect inline-block w-full h-full"
    :style="{
      '--border-opacity': isMounted ? '100%' : '0%',
      '--circle-radius': '35rem',
      '--x': `${x}px`,
      '--y': `${y}px`
    }"
  >
    <div 
      class="absolute inset-.5 rounded-2.75 bg-brand-background" 
    />

    <div class="relative isolate flex items-stretch justify-stretch h-full w-full">
      <slot></slot>
    </div>
  </div>

</template>

<style scoped>

  .border-effect::before {
    opacity: var(--border-opacity, 0%); /* avoid flashing before mount */
    pointer-events: none;
    content: '';
    position: absolute;
    inset: 0;
    background-attachment: scroll;
    background-image: radial-gradient(var(--circle-radius) circle at var(--x) var(--y), var(--brand-primary) 30%, transparent 100%);
    background-clip: border-box;
    border-radius: 12px;
  }

  .border-effect::after {
    transition: 300ms ease-in-out;
    pointer-events: none;
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0%;
    background-attachment: scroll;
    background-image: radial-gradient(var(--circle-radius) circle at var(--x) var(--y), var(--brand-primary) 30%, transparent 100%);
    background-clip: border-box;
    border-radius: 12px;
  }

  .border-effect:hover::after {
    opacity: 5%
  }

</style>